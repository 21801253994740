import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";

// local
// const API_URL = "http://192.168.21.19:5001"
// const SITE_URL = "http://localhost:3000/"
// const STRIPE_PUBLIC_KEY=
//      "pk_test_51OHOUnH7v0ZRpA9ZwzSZvPsbpkvmhYvY2YvFYCTwbDxfB23DxqttV9s6AO6H7A6CgnQzV2qUvAUCligjSj0dCmYh00rqpEzUyX"

//demo
const API_URL = "https://marius.admin.iossmlm.com";
const SITE_URL = "https://marius.user.iossmlm.com/";
const STRIPE_PUBLIC_KEY =
  "pk_test_51OHOUnH7v0ZRpA9ZwzSZvPsbpkvmhYvY2YvFYCTwbDxfB23DxqttV9s6AO6H7A6CgnQzV2qUvAUCligjSj0dCmYh00rqpEzUyX";

//live
// const API_URL = "https://admin.prosperityro.com";
// const SITE_URL = "https://prosperityro.eu/";
// const STRIPE_PUBLIC_KEY =
//   "pk_live_51HNHMhIWDPLlgYb43zCT8xDxRvQaThm0UDnRrkPXaDiPdJxQvwWJYtGnSyJ8OH2bl2EQOH95k4e2AqcQEGsapLkm00IF0aOdHf";

const BASE_URL = `${API_URL}/node_api/web/`;
const IMG_URL = `${API_URL}uploads/images/`;
const BASE = `${API_URL}`;
const LEAD_URL = `${API_URL}node_api/`;

export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
  SITE_URL,
  STRIPE_PUBLIC_KEY,
};
